// @flow strict
import React from 'react';
import { Link } from 'gatsby';
import Comments from './Comments';
import Content from './Content';
import Meta from './Meta';
import Tags from './Tags';
import styles from './Post.module.scss';
import type { Node } from '../../types';
import Share from './Share';

type Props = {
	post: Node,
};

const Post = ({ post }: Props) => {
	const { html } = post;
	const { tagSlugs, slug } = post.fields;
	const { tags, title, date } = post.frontmatter;

	return (
		<div className={styles['post']}>
			<Link className={styles['post__home-button']} to='/'>
				All Articles
			</Link>

			<div className={styles['post__content']}>
				<Content body={html} title={title} date={date} />
			</div>

			<div className={styles['post__footer']}>
				<Share title={title} />
				{tags && tagSlugs && <Tags tags={tags} tagSlugs={tagSlugs} />}
			</div>

			<div className={styles['post__comments']}>
				<Comments postSlug={slug} postTitle={post.frontmatter.title} />
			</div>
		</div>
	);
};

export default Post;
